



















































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import LineSelectCard from '@/components/LineSelectCard/LineSelectCard.vue';
import GoodsSelect from './components/GoodsSelect.vue';
import TransportTypeSelect from './components/TransportTypeSelect.vue';
import carrierSelect from './components/carrierSelect.vue';
import EstimationModule from '@/views/charge-standard/components/estimation-module.vue';
import ChargeDetails from './module/charge-details.vue';

import { validators } from '@/utils/validate';
import { GetPriceSheet } from '@/api/api';

@Component({
    name: 'ChargeStandard',
    components: {
        LineSelectCard,
        GoodsSelect,
        TransportTypeSelect,
        carrierSelect,
        EstimationModule,
        ChargeDetails
    }
})
export default class ChargeStandard extends Vue {
    @Ref('EstimationModule') public readonly EstimationModuleRef;
    @Ref('ChargeDetails') public readonly ChargeDetailsRef;

    public isFTable: boolean = false;

    public visible: boolean = false;

    public loading: boolean = false;

    public formData: any = {
        CountryIds: '',
        GoodsTypeId: '',
        Weight: '',
        TransportTypeId: '',
        carrierId: '',
        Length: '',
        Width: '',
        Height: '',
        gross: 0 // 税金
    };

    // 这里判断方法模式
    public BillingModeIdType: any = '';

    public data = [];
    public dataCarrier = [];

    public formRules: any = {
        Weight: [validators.required]
    };

    // 获取报价
    public async init(type) {
        try {
            this.loading = true;
            // eslint-disable-next-line eqeqeq
            if (this.formData.CountryIds == '') {
                return;
            }

            // eslint-disable-next-line eqeqeq
            if (this.formData.GoodsTypeId == '') {
                return;
            }

            if (type !== 3) {
                const { Data } = await GetPriceSheet({
                    CountryId: this.formData.CountryIds.split('|')[1] || '',
                    CountryFromId: this.formData.CountryIds.split('|')[0] || '',
                    GoodsTypeId: this.formData.GoodsTypeId
                });
                this.data = Data;
                this.formData.TransportTypeId = Data[0].TransportTypeId;
            }

            // eslint-disable-next-line eqeqeq
            const obj = this.data.find((e) => e.TransportTypeId == this.formData.TransportTypeId);
            if (obj) {
                this.formData.carrierId = obj.ListShopPriceSheetDetailV2[0].CarrierIds;
                this.BillingModeIdType = obj.ListShopPriceSheetDetailV2[0].BillingModeId;
            }

            if (!this.disabled) {
                this.EstimationModuleRef.open(this.formData);
            }
        } catch (error) {
            console.warn(error);
        } finally {
            this.loading = false;
        }
    }

    public ChangeCarrier(row) {
        this.BillingModeIdType = row.BillingModeId;
    }

    public handleAClick() {
        let target:any = event.target;
        // 递归向上查找直到找到 <a> 标签
        while (target && target.tagName !== 'A') {
            target = target.parentElement;
        }
        // 确保找到了 <a> 标签
        if (target && target.tagName === 'A') {
            // 阻止默认行为
            event.preventDefault();
            // 获取 href 属性
            const href = target.getAttribute('href');
            if (href) {
                this.ChargeDetailsRef.open(href);
            }
        }
    }

    // 这里做表格合并处理
    public get tableOneData() {
        let tableOneArr = [];
        // eslint-disable-next-line eqeqeq
        const obj = this.data.find((e) => e.TransportTypeId == this.formData.TransportTypeId);
        if (obj) {
            const arrC = [];
            //  挑选数据给承运商
            obj.ListShopPriceSheetDetailV2.forEach((item) => {
                const arrOp = {
                    CarrierIds: item.CarrierIds,
                    CarrierNames: item.CarrierNames,
                    BillingModeId: item.BillingModeId
                };
                // 如果对象不存在
                if (!arrC.some(m => m.CarrierIds === item.CarrierIds)) {
                    arrC.push(arrOp);
                }
            });
            // 去重后的数据
            this.dataCarrier = arrC;
            // 表格合并的重要数据
            obj.ListShopPriceSheetDetailV2.forEach((item) => {
                // 如果是日本的 或者属于海运的
                if (this.isJapan) {
                    // 找出对应的承运商
                    // eslint-disable-next-line eqeqeq
                    if (item.CarrierIds == this.formData.carrierId) {
                        // 方法一
                        // eslint-disable-next-line eqeqeq
                        if (this.BillingModeIdType == '1') {
                            if (item.ListShopPriceSheetStandard.length) {
                                item.ListShopPriceSheetStandard[0].customWareHouseNames = item.WareHouseNames;
                                item.ListShopPriceSheetStandard[0].customCarrierNames = item.CarrierNames;
                                item.ListShopPriceSheetStandard[0].rowspan = item.ListShopPriceSheetStandard.length;
                                item.ListShopPriceSheetStandard.map((row) => {
                                    row.FirstWeightStandard = row.FirstWeightStandard.replace('元/Kg', '');
                                    row.TaxFirstWeightStandard = row.TaxFirstWeightStandard.replace('元/Kg', '');
                                    row.SecondWeightStandard = row.SecondWeightStandard.replace('元/Kg', '');
                                    row.TaxSecondWeightStandard = row.TaxSecondWeightStandard.replace('元/Kg', '');
                                    return row;
                                });
                            }
                            tableOneArr = [...item.ListShopPriceSheetStandard, ...tableOneArr];
                        // eslint-disable-next-line eqeqeq
                        } else if (this.BillingModeIdType == '2') {
                            if (item.ListShopPriceSheetStandardV2.length) {
                                item.ListShopPriceSheetStandardV2[0].customWareHouseNames = item.WareHouseNames;
                                // item.ListShopPriceSheetStandard[0].customCarrierNames = item.CarrierNames;
                                item.ListShopPriceSheetStandardV2[0].rowspan = item.ListShopPriceSheetStandardV2.length;
                            }
                            tableOneArr = [...item.ListShopPriceSheetStandardV2, ...tableOneArr];
                        } else {
                            item.ListShopPriceSheetStandardV3.map((row) => {
                                row.customWareHouseNames = item.WareHouseNames;
                                return row;
                            });
                            tableOneArr = [...item.ListShopPriceSheetStandardV3, ...tableOneArr];
                        }
                    }
                } else {
                    if (item.ListShopPriceSheetStandard.length) {
                        item.ListShopPriceSheetStandard[0].customWareHouseNames = item.WareHouseNames;
                        item.ListShopPriceSheetStandard[0].customCarrierNames = item.CarrierNames;
                        item.ListShopPriceSheetStandard[0].rowspan = item.ListShopPriceSheetStandard.length;
                        item.ListShopPriceSheetStandard.map((row) => {
                            row.FirstWeightStandard = row.FirstWeightStandard.replace('元/Kg', '');
                            row.TaxFirstWeightStandard = row.TaxFirstWeightStandard.replace('元/Kg', '');
                            row.SecondWeightStandard = row.SecondWeightStandard.replace('元/Kg', '');
                            row.TaxSecondWeightStandard = row.TaxSecondWeightStandard.replace('元/Kg', '');
                            return row;
                        });
                    }
                    tableOneArr = [...item.ListShopPriceSheetStandard, ...tableOneArr];
                }
            });
        }
        return tableOneArr;
    }

    // 根据后端返回的树形数据，筛选出对应的某项数据
    public get TransportObj() {
        let GuideDetail = '';
        // eslint-disable-next-line eqeqeq
        const obj = this.data.find((e) => e.TransportTypeId == this.formData.TransportTypeId);
        GuideDetail = obj.GuideDetail;
        if (obj.ListShopPriceSheetDetailV2.length) {
            // 查找出承运商的数据
            // eslint-disable-next-line eqeqeq
            const objCarrier = obj.ListShopPriceSheetDetailV2.find((e) => e.CarrierIds == this.formData.carrierId);
            if (objCarrier.GuideDetail) {
                GuideDetail = objCarrier.GuideDetail;
            }
        }
        return GuideDetail;
    }

        // 合并单元格
    public objectSpanMethod(obj) {
        const flag = {
            columnIndex: 0, // 第一列
            rowIndex: 0 // 第一行
        };

        // 已经换列的话重给标识
        if (obj.rowIndex !== flag.rowIndex) {
            flag.rowIndex = obj.rowIndex;
        }

        // 第n行 第一列
        if (flag.rowIndex === obj.rowIndex && obj.columnIndex === 0) {
            return {
                rowspan: obj.row.rowspan,
                colspan: 1
            };
        }
    }

    public get disabled() {
        let is = true;
        if (this.formData.CountryIds && this.formData.GoodsTypeId && this.formData.TransportTypeId && this.formData.Weight) {
            is = false;
        }
        return is;
    }

    // 如果是日本 或者选择了海运
    public get isJapan() {
        let is = false;
        // eslint-disable-next-line eqeqeq
        if (this.formData.CountryIds.indexOf('1623') != -1 || this.formData.TransportTypeId == 4) {
            is = true;
        }
        return is;
    }
}
